// LOCAL
export const REACT_SECRET_KEY = 'WoxY4pwtGJ21YvKabCZrpxsBN0k7WAreNd1LraJJDWI=';
export const REACT_IV = 'MENzvd8wcV9ninOqFR1kng==';
export const REACT_BE_BASE_URL = 'https://abbecardbeprod.icysky-c40a8f50.westeurope.azurecontainerapps.io';
export const REACT_REDIRECT_URI = 'https://seasons-greetings.abb.com/login';
export const REACT_AUTHORITY = 'https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd';
export const REACT_CLIENT_ID = '42bfe240-536d-4fe0-9cc1-8c3643890f73';
export const REACT_FE_BASE_URI = 'https://seasons-greetings.abb.com';
export const SENDER_FLOW_ACTIVE_UNTIL = '2025-01-12';
export const RECEIVER_FLOW_ACTIVE_UNTIL = '';
